import React from "react";
import { Link } from "gatsby";

import TEDxKIContainer from '../components/tedxki-container'

import * as styles from './get-involved.module.css'

export default function GetInvolvedPage() {

  return (
    <TEDxKIContainer>
      <div className={styles.tedxki_container}>
        <h1>
          We need you!
        </h1>

        <h4>
          Anyone can get involved with the TEDxKI community and there are numerous ways to do that.
          From contributing to the organization of the event and volunteering during this sharing of ideas to
          nominating a friend or becoming the next speaker, you can help create a stimulating day of inspiration!
        </h4>

        <div className="home-page__subtitle">
          <span className={styles.not_active}><h6><a href="mailto:executiveteam@tedxki.com">Become a volunteer ></a></h6></span>
          <p>
            Fantastic that you would like to volunteer for TEDxKI and contribute to the community! Helpers are people from the KI community who provide additional support on the day of the event. Anyone can volunteer, and opportunities will be announced a few weeks before the event. Helpers are essential in smoothly organizing the event by helping team members and inspiring guests with enthusiasm.
          </p>
        </div>

        <div className="home-page__subtitle">
          <span className={styles.not_active}><h6><a href="mailto:executiveteam@tedxki.com">Nominate a speaker or a performer ></a></h6></span>
          <p>
            Our speaker and performer lineup for our 2024 event is open. If you want to nominate a speaker or a performer, please contact us via our email or our social media.
          </p>
        </div>

        <div className="home-page__subtitle">
          <span className={styles.not_active}><h6><a href="mailto:executiveteam@tedxki.com">Join the next event's team ></a></h6></span>
          <p>
            Positions on the teams become available each year and as needed. Make sure to apply to the upcoming team recruitment in fall 2024. <br />
            Depending on your experience and skills, there may be a team where you can contribute the most. We are looking for passionate people about TED, who have experience organizing events/activities/groups, and working in a multicultural environment.
          </p>
        </div>

      </div>
    </TEDxKIContainer>
  )
};
